import React, { useState } from 'react';
import './css/navbar.css';
import logou from '../pages/img/logou.png';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {
    FaFacebookF,
    FaTiktok,
    FaBars,
    FaHome,
    FaCamera,
    FaTools,
    FaInfoCircle,
    FaImages,
    FaBlog,
    FaHeadset,
    FaMapMarkerAlt,
    FaInstagram,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';
import { Modal } from 'react-bootstrap';

const Navbar = () => {
    const [showModal, setShowModal] = useState(false);

    const handleToggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg p-3 sticky-top border-bottom shadow-sm bg-white">
                <div className="container">
                    {/* <NavLink className="navbar-brand" to="/">My Website</NavLink> */}
                    <NavLink className="navbar-brand" to="/">
                        <img src={logou} alt="Logo" width="150" height="30" className="img-fluid" />
                    </NavLink>
                    <button
                        className="navbar-toggler btn-danger text-danger no-border-on-hover"
                        style={{ border: 'none' }}
                        type="button"
                        aria-expanded={showModal}
                        aria-label="Toggle navigation"
                        onClick={handleToggleModal}
                    >
                        {showModal ? <FaBars /> : <FaBars />}
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav justify-content-center flex-grow-1 gap-md-3">
                            <NavLink className={({ isActive }) => isActive ? "nav-link poppins-medium active text-danger" : "nav-link poppins-medium"} to="/" end>Beranda</NavLink>
                            <NavLink className={({ isActive }) => isActive ? "nav-link poppins-medium active text-danger" : "nav-link poppins-medium"} to="/products">Produk</NavLink>
                            <NavLink className={({ isActive }) => isActive ? "nav-link poppins-medium active text-danger" : "nav-link poppins-medium"} to="/services">Layanan</NavLink>
                            <NavLink className={({ isActive }) => isActive ? "nav-link poppins-medium active text-danger" : "nav-link poppins-medium"} to="/portfolio">Portofolio</NavLink>
                            <NavLink className={({ isActive }) => isActive ? "nav-link poppins-medium active text-danger" : "nav-link poppins-medium"} to="/about">Tentang Kami</NavLink>
                        </ul>

                        <div className="ms-auto">
                            <a href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-outline-danger" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal', fontSize: '16px' }}>Hubungi Kami</div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            <Modal show={showModal} onHide={handleToggleModal} size="lg" fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, fontStyle: 'normal', fontSize: '17px' }}>CCTV Banyuwangi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="list-group">
                        <NavLink to="/" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaHome className="me-3" /> Beranda
                        </NavLink>
                        <NavLink to="/products" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaCamera className="me-3" /> Produk
                        </NavLink>
                        <NavLink to="/services" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaTools className="me-3" /> Layanan
                        </NavLink>
                        <NavLink to="/portfolio" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaImages className="me-3" /> Portofolio
                        </NavLink>
                        <NavLink to="/blog" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaBlog className="me-3" /> Blog
                        </NavLink>
                        <NavLink to="/support" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaHeadset className="me-3" /> Support
                        </NavLink>
                        <NavLink to="/contact" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaMapMarkerAlt className="me-3" /> Alamat Kantor
                        </NavLink>
                        <NavLink to="/about" className="list-group-item active-nav-item list-group-item-action d-flex align-items-center poppins-medium" onClick={handleToggleModal}>
                            <FaInfoCircle className="me-3" /> Tentang Kami
                        </NavLink>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <a href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-outline-danger" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal', fontSize: '16px' }}>Hubungi Kami</button>
                        </a>
                    </div>
                    <div className="social-icons d-flex justify-content-center mt-4 gap-4">
                        <a href="https://facebook.com/yourusername" className="text-primary">
                            <FaFacebookF size="24" />
                        </a>
                        <a href="https://instagram.com/yourusername" className="text-danger">
                            <FaInstagram size="24" />
                        </a>
                        <a href="https://twitter.com/yourusername" className="text-info">
                            <FaTwitter size="24" />
                        </a>
                        <a href="https://youtube.com/channel/yourchannel" className="text-danger">
                            <FaYoutube size="24" />
                        </a>
                        <a href="https://tiktok.com/@yourusername" className="text-black">
                            <FaTiktok size="24" />
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Navbar;
