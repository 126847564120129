import React from 'react';
import Slider from "react-slick"; 
import './css/slider.css';

// import cpplus from './images/cpplus.png';
import dahua from './images/dahua.png';
import ezviz from './images/ezviz.png';
import hikvision from './images/hikvision.png';
import hilook from './images/hilook.png';
import logospc from './images/logo-spc.png';
// import uniview from './images/uniview.png';

const ImageSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='container'>
            <Slider {...settings}>
                <div>
                    <img src={hilook} alt="EZVIZ CCTV Product" className="slider-img"/>
                </div>
                <div>
                    <img src={ezviz} alt="Hikvision Company Logo" className="slider-img"/>
                </div>
                <div>
                    <img src={hikvision} alt="SPC Brand Logo" className="slider-img"/>
                </div>
                <div>
                    <img src={dahua} alt="Hikvision CCTV Camera" className="slider-img"/>
                </div>
                <div>
                    <img src={logospc} alt="Another view of EZVIZ CCTV Camera" className="slider-img"/>
                </div>
                {/* <div>
                    <img src={uniview} alt="Another view of EZVIZ CCTV Camera" className="slider-img"/>
                </div> */}
                {/* <div>
                    <img src={cpplus} alt="Another view of EZVIZ CCTV Camera" className="slider-img"/>
                </div> */}
            </Slider>
        </div>
    );
};

export default ImageSlider;
