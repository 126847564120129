import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import { FaChalkboardTeacher, FaMicroscope, FaCertificate, FaProjectDiagram, FaUsers, FaCity, FaBuilding } from 'react-icons/fa';
import ImageSlider from '../components/ImageSlider';
import './css/homepage.css';
import logo1 from './img/logo3.png';
import checkIcon from './img/checked-i.png';
import cameraImage from './img/smart.png';
import bg from './img/bg1.jpg';
import clintt from './img/client1.png';
import bg22 from './img/bg221.png';

const Homepages = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 1
            }
        }
    };

    const itemVariants = {
        hidden: { x: -100, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1 }
        }
    };

    const itemVariants2 = {
        hidden: { x: 100, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 1.5 }
        }
    };

    const itemVariants3 = {
        hidden: { x: -100, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { type: 'spring', stiffness: 120 }
        }
    };
    const containerVariants2 = {
        hidden: { opacity: 1, scale: 0.8 }, 
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                staggerChildren: 0.3, 
                delayChildren: 1    
            }
        }
    };
    
    const cardVariants = {
        hidden: { 
            opacity: 0, 
            rotateY: 90 
        },
        visible: {
            opacity: 1,
            rotateY: 0,
            transition: {
                duration: 0.5
            }
        }
    };
    
    
    

    return (
        <div>
            <div className="custom-flex-container" style={{ backgroundImage: `url(${bg})` }}>
                <div className="container" style={{ height: '100%' }}>
                    <div className="row h-100">
                        <div className="col d-flex justify-content-end align-items-start ct" style={{ paddingTop: '20%' }}>
                            <motion.div 
                                className="ct2" 
                                style={{ color: 'white', fontSize: '24px' }}
                                variants={containerVariants}
                                initial="hidden"
                                animate="visible"
                            >
                                <div className='w-100'>
                                <motion.p 
                                    className='poppins-regular title-header-content'
                                    variants={itemVariants}
                                >
                                    Jasa pemasangan & Penjualan
                                    <span className='poppins-bold' style={{ color: 'rgb(255, 0, 0)' }}> CCTV</span> di Banyuwangi
                                </motion.p>
                                </div>
                                <motion.p 
                                    className='poppins-bold title-header-content2'
                                    style={{fontSize: "70px", marginTop:"-25px"}}
                                    variants={itemVariants2}
                                >
                                    Solusi <span style={{ color: 'rgb(255, 0, 0)' }}>Keamanan</span> Ideal <br/> Rumah & Bisnis Anda
                                </motion.p>
                                <div className='d-flex gap-3'>
                                    <a className='title-tawaran3 dis-none' href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                                            <div className="btn btn-danger btn-tawaran" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal', fontSize: '13px' }}>Konsultasi Sekarang</div>
                                    </a>
                                    <a className='title-tawaran3 dis-none' href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                                            <div className="btn btn-outline-light btn-tawaran" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal', fontSize: '13px' }}>Tentang Kami</div>
                                    </a>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container custom-margins-padding">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className=''>
                            <h1 className="cctv-title font-weight-bold poppins-bold text-danger mb-0">CCTV BANYUWANGI</h1>
                            <p className="cctv-subtitle mb-0 poppins-semibold-italic">
                                Solusi Keamanan Terpercaya untuk Rumah dan Usaha Anda
                            </p>
                        </div>
                        <p className="cctv-description poppins-regular mt-4 pt-2">
                            CCTV Banyuwangi menyediakan produk dan pemasangan CCTV berkualitas tinggi dengan teknologi terbaru, layanan cepat, aman, dan terpercaya, didukung tim profesional serta purna jual terbaik. Lindungi properti Anda dengan mitra keamanan terpercaya.
                        </p>
                        <motion.div className="d-flex justify-content-between mt-4 pt-2 icc"
                            variants={containerVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <motion.div className="d-flex align-items-center justify-content-center"
                                variants={itemVariants3}
                            >
                                <img src={checkIcon} alt="100% Terpercaya" className="check-icon img-fluid" />
                                <p className="poppins-medium mb-0 check-des">100% Terpercaya</p>
                            </motion.div>
                            <motion.div className="d-flex align-items-center justify-content-center"
                                variants={itemVariants3}
                            >
                                <img src={checkIcon} alt="Bahan Berkualitas" className="check-icon img-fluid" />
                                <p className="poppins-medium mb-0 check-des">Bahan Berkualitas</p>
                            </motion.div>
                            <motion.div className="d-flex align-items-center justify-content-center"
                                variants={itemVariants3}
                            >
                                <img src={checkIcon} alt="Layanan Terbaik" className="check-icon img-fluid" />
                                <p className="poppins-medium mb-0 check-des">Layanan Terbaik</p>
                            </motion.div>
                        </motion.div>
                    </div>
                    <div className="col-md-6 text-center">
                        <img src={logo1} alt="CCTV Installation" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container mt-1 pt-4 title-ai">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={cameraImage} alt="Camera" className="img-fluid img-mobile-none" />
                    </div>
                    <div className="col-md-6">
                        <h3 className="mt-4 poppins-bold mb-4">Tingkatkan Keamanan Rumah dengan Teknologi AI dari CCTV Banyuwangi</h3>
                        <img src={cameraImage} alt="Camera" className="img-fluid img-mobile-show" />
                        <ul>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Deteksi Gerakan Pintar:</strong> Peringatan real-time saat ada gerakan mencurigakan.</li>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Pengenal Wajah:</strong> Identifikasi otomatis untuk keamanan ekstra.</li>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Pengelompokan Objek:</strong> Bedakan manusia, hewan, dan objek lain.</li>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Pengawasan Malam Hari:</strong> Inframerah AI untuk visibilitas malam yang maksimal.</li>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Peringatan Proaktif:</strong> Notifikasi otomatis saat ada ancaman.</li>
                            <li className='poppins-regular mb-1 cctv-description'><strong>Pelacakan Otomatis:</strong> Kamera mengikuti gerakan mencurigakan secara otomatis.</li>
                        </ul>
                        <div className='text-center'>
                            <a className='mt-3' href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-danger btn-ai" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal', fontSize: '16px' }}>Pasang Sekarang</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'rgba(200, 200, 200, 0.2)', padding: '30px' }} className='rounded mt-5 mb-5'>
                <div className="container">
                    <h2 className="text-center poppins-bold mb-4 pb-2 mt-3 haruskami-t ">KENAPA HARUS DI CCTV BANYUWANGI?</h2>
                    <div className="d-flex justify-content-center">
                        <span style={{
                            display: 'block',
                            width: '70px',
                            height: '3px',
                            backgroundColor: 'red',
                            marginTop: '-15px'
                        }}>
                        </span>
                    </div>
                    <div className="row text-center justify-content-center g-4 custom-gap p-3">
                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaChalkboardTeacher size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">Garansi Resmi</h4>
                            <p className="poppins-regular haruskami">
                                Setiap pembelian dilengkapi garansi resmi, memastikan kualitas dan keamanan jangka panjang.
                            </p>
                        </div>

                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaMicroscope size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">Pemasangan Rapi</h4>
                            <p className="poppins-regular haruskami">
                                Pemasangan cepat dan rapi, memberikan hasil yang bersih dan siap pakai.
                            </p>
                        </div>

                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaCertificate size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">20 Tahun Pengalaman</h4>
                            <p className="poppins-regular haruskami">
                                Berpengalaman lebih dari 20 tahun, memberikan solusi keamanan terbaik.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
            <h2 className='poppins-bold'>Tentang Kami</h2>
                        <span style={{
                            display: 'block',
                            width: '70px',
                            height: '4px',
                            backgroundColor: 'red',
                            marginTop: '-2px',
                            marginBottom: '10px'
                        }}>
                        </span>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <p className='poppins-regular cctv-description' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>CCTV Banyuwangi telah aktif dalam bisnis penjualan dan pemasangan CCTV sejak tahun 2019. kami melayani berbagai kebutuhan pengawasan dengan menyediakan produk CCTV berkualitas tinggi serta layanan pemasangan yang profesional. Dengan tim ahli yang berpengalaman, kami berkomitmen untuk memberikan solusi keamanan yang terbaik dan paling efektif bagi setiap klien kami, meliputi perusahaan, instansi pemerintah, hingga pemilik rumah di seluruh wilayah Indonesia. Kepercayaan dan kepuasan klien adalah prioritas kami, dan kami selalu siap untuk membantu meningkatkan keamanan yang sesuai dengan kebutuhan pelanggan.</p>
                    </div>
                    <div className="col-md-6">
                        <motion.div
                            className="row text-center justify-content-center"
                            variants={containerVariants2}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                        >
                            {/** Repeat for each card **/}
                            <div className="col-6 mb-3">
                                <motion.div 
                                    className="card h-100"
                                    variants={cardVariants}
                                >
                                    <div className="card-body shadow">
                                        <h3 className="card-title"><FaProjectDiagram /> 700+</h3>
                                        <p className="card-text poppins-regular tt-kami">Proyek Sukses</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-6 mb-3">
                                <motion.div 
                                    className="card h-100"
                                    variants={cardVariants}
                                >
                                    <div className="card-body shadow">
                                        <h3 className="card-title"><FaUsers /> 2000+</h3>
                                        <p className="card-text poppins-regular tt-kami">Klien</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-6 mb-3">
                                <motion.div 
                                    className="card h-100"
                                    variants={cardVariants}
                                >
                                    <div className="card-body shadow">
                                        <h3 className="card-title"><FaCity /> 1200+</h3>
                                        <p className="card-text poppins-regular tt-kami">Maintenance</p>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-6 mb-3">
                                <motion.div 
                                    className="card h-100"
                                    variants={cardVariants}
                                >
                                    <div className="card-body shadow">
                                        <h3 className="card-title"><FaBuilding /> Office</h3>
                                        <p className="card-text poppins-regular tt-kami">Banyuwangi</p>
                                    </div>
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>

            <div>
                <div className='text-center mb-5'>
                <h2 className='poppins-bold'>
                    Patner Kami
                </h2>
                <p className='poppins-regular slug-t' style={{marginTop:'-10px'}}>Perusahaan dan instansi yang Mempercayakan kami</p>
                <img src={clintt} alt="Camera" className="img-fluid" />
                </div>
            </div>

            <div 
                style={{
                    backgroundImage: `url(${bg22})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    padding: '30px',
                    height: '60vh'
                }}

                className='rounded mt-5 mb-5 c-tw'
                >
                <div className=' container'>
                    <h1 className='poppins-bold title-tawaran'>Apa yang anda tunggu lagi ?</h1>
                    <p className='poppins-medium title-tawaran2'>Hubungi kami sekarang dan konsultasikan kebutuhan anda <br/> pada kami, kami siap melayani anda sepenuh hati</p>
                    <a className='title-tawaran3' href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?" target="_blank" rel="noopener noreferrer">
                            <div className="btn btn-danger btn-tawaran" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontStyle: 'normal'}}>Hubungi Kami</div>
                    </a>
                </div>
            </div>

            <div className='container mt-5 mb-5' style={{ width: '100%' }}>
                <h2 className='text-center poppins-bold mb-4'>
                    Alamat Kami
                </h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7897.7113050311145!2d114.35868844350232!3d-8.217268092475626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd1452dbdae16a1%3A0x1cb7e8422920efad!2sCctv%20Banyuwangi!5e0!3m2!1sen!2sid!4v1725946930633!5m2!1sen!2sid"
                    style={{ width: '100%', height: '450px', border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Lokasi Kantor Cctv Banyuwangi"
                />
            </div>

            <ImageSlider />
            <div className='mt-5'></div>
        </div>
    );
};

export default Homepages;
